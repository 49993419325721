import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";

const AddWorkouts = ({ CloseWorkoutsSchedule }) => {
  const initialFormData = {
    user_ids: [],
    workout_ids: [],
    dates: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [workouts, setWorkouts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const token = "1|leFVcRWGyOqhNB6nQfisVpOLnKDaZEINlkA7H0x2c385f3b0";

  useEffect(() => {
    fetchWorkouts();
    fetchUsers();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/workouts?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts(response.data.data);
    } catch (error) {
      console.error("Error fetching workouts:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/users?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleWorkoutChange = (e) => {
    const { options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormValues({ ...formValues, workout_ids: selectedOptions });
  };

  const handleUserChange = (e) => {
    const { options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormValues({ ...formValues, user_ids: selectedOptions });
  };

  const handleDateChange = (dates) => {
    const dateStrings = dates.map((date) => date.format("YYYY-MM-DD"));
    setSelectedDates(dates);
    setFormValues({
      ...formValues,
      dates: dateStrings,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requests = formValues.dates.map((date) =>
        formValues.user_ids.map((user_id) =>
          formValues.workout_ids.map(async (workout_id) => {
            const payload = {
              workout_id: parseInt(workout_id, 10),
              user_id: parseInt(user_id, 10),
              workout_schedule_date: date,
            };

            try {
              const response = await axios.post(
                "https://api.drmicheladib.com/api/v1/admin/workout/add-user",
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log("Workout assigned successfully", response.data);
            } catch (error) {
              console.error("Error assigning workout:", error);
              alert(
                `Error: ${error.response ? error.response.data : error.message}`
              );
            }
          })
        )
      );

      await Promise.all(requests.flat(2)); // Flatten the nested arrays and await all promises
      CloseWorkoutsSchedule();
    } catch (error) {
      console.error("Error assigning workout:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Users
              </h2>
              <select
                id="user_ids"
                name="user_ids"
                multiple
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                value={formValues.user_ids}
                onChange={handleUserChange}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Workouts
              </h2>
              <select
                id="workout_ids"
                name="workout_ids"
                multiple
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                value={formValues.workout_ids}
                onChange={handleWorkoutChange}
              >
                {workouts.map((workout) => (
                  <option key={workout.id} value={workout.id}>
                    {workout.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Select Dates
              </h2>
              <DatePicker
                multiple
                value={selectedDates}
                onChange={handleDateChange}
                // className="custom-datepicker"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={CloseWorkoutsSchedule}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 focus:outline-none"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddWorkouts;
