import React, { useState, useEffect } from "react";
import axios from "axios";

const AddForm = ({ onSave, onCancel }) => {
  const initialFormData = {
    id: null,
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
    tags: [],
    exercise_ids: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [exercises, setExercises] = useState([]);
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchExercises();
  }, []);

  const fetchExercises = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/exercises?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExercises(response.data.data);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("tag_")) {
      const index = parseInt(name.split("_")[1]);
      const newTags = [...formValues.tags];
      newTags[index] = value;
      setFormValues({ ...formValues, tags: newTags });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleAddTag = () => {
    setFormValues({
      ...formValues,
      tags: [...formValues.tags, ""],
    });
  };

  const handleRemoveTag = (index) => {
    const newTags = formValues.tags.filter((_, i) => i !== index);
    setFormValues({ ...formValues, tags: newTags });
  };

  const handleExerciseChange = (e) => {
    const { options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormValues({ ...formValues, exercise_ids: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await onSave({
        ...formValues,
        tags: formValues.tags.filter(
          (tag) => typeof tag === "string" && tag.trim() !== ""
        ),
      });
      console.log("Meal updated successfully");
    } catch (error) {
      console.error("Error updating meal:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-xl w-full mt-10">
        <form
          className="mt-5 max-h-[80vh] overflow-y-auto space-y-6"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="title_en"
                  name="title_en"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  value={formValues.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="title_ar"
                  name="title_ar"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  value={formValues.title_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Tags
              </h2>
              {formValues.tags.map((tag, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`tag_${index}`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Tag Name"
                    value={tag}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveTag(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Description
              </h2>
              <textarea
                id="description_en"
                name="description_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                value={formValues.description_en}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Description
              </h2>
              <textarea
                id="description_ar"
                name="description_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                value={formValues.description_ar}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Exercises
              </h2>
              <select
                id="exercise_ids"
                name="exercise_ids"
                multiple
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                value={formValues.exercise_ids}
                onChange={handleExerciseChange}
              >
                {exercises.map((exercise) => (
                  <option key={exercise.id} value={exercise.id}>
                    {exercise.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 focus:outline-none"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddForm;
