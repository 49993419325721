import React, { useState } from "react";
import axios from "axios";

const EditPlans = ({ plan, token, setPlans, toggleEditMode }) => {
  const [updatedPlan, setUpdatedPlan] = useState(plan);

  const handlePriceChange = (key, value) => {
    setUpdatedPlan((prevPlan) => ({ ...prevPlan, [key]: value }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/plans/${plan.id}`,
        updatedPlan,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 202) {
        setPlans((prevPlans) =>
          prevPlans.map((p) => (p.id === plan.id ? updatedPlan : p))
        );
        toggleEditMode(plan.id);
      } else {
        alert("Failed to update prices. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        alert(
          `Error: ${
            error.response.data.message ||
            "An error occurred while updating prices."
          }`
        );
      } else if (error.request) {
        console.error("Network Error:", error.request);
        alert("Network error. Please try again later.");
      } else {
        console.error("Error:", error.message);
        alert("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="p-4 bg-gray-100 rounded">
      <div className="space-y-4">
        <div className="flex items-center">
          <label className="w-full">Price (USD)</label>
          <input
            type="number"
            name={plan.name}
            className="border p-2 rounded w-32 mr-3"
            value={updatedPlan.price || ""}
            onChange={(e) => handlePriceChange("price", e.target.value)}
          />
          <span className="ml-2">$</span>
        </div>
        <div className="flex items-center">
          <label className="w-full">Price (Syria, S.P)</label>
          <input
            type="number"
            className="border p-2 rounded w-32"
            value={updatedPlan.syr_price || ""}
            onChange={(e) => handlePriceChange("syr_price", e.target.value)}
          />
          <span className="ml-2">S.P</span>
        </div>
      </div>
      <button
        className="bg-green-500 text-white py-2 px-4 rounded mt-4"
        onClick={handleSubmit}
      >
        Save Changes
      </button>
    </div>
  );
};

export default EditPlans;
