import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlusCircle, FaEdit, FaTrash } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";

const Exercises = () => {
  const [exercises, setExercises] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc', 'desc', 'oldest', 'newest'

  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchExercises();
  }, [exercises]);

  const fetchExercises = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/exercises?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExercises(response.data.data);
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const handleSave = (newExercise) => {
    setExercises([...exercises, newExercise]);
    setShowAddForm(false);
  };

  const deleteExercise = async (id) => {
    try {
      await axios.delete(
        `https://api.drmicheladib.com/api/v1/admin/exercises/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExercises(exercises.filter((exercise) => exercise.id !== id));
    } catch (error) {
      console.error("Error deleting exercise:", error);
    }
  };

  const handleEditClick = (exercise) => {
    setCurrentExercise(exercise);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentExercise({ ...currentExercise, [name]: value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const getSortedExercises = (exercises) => {
    let sortedExercises = [...exercises];

    if (sortOrder === "asc") {
      sortedExercises.sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      );
    } else if (sortOrder === "desc") {
      sortedExercises.sort((a, b) =>
        (b.name || "").localeCompare(a.name || "")
      );
    } else if (sortOrder === "oldest") {
      sortedExercises.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedExercises.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedExercises;
  };

  // Filter and sort exercises based on search query and sort order
  const filteredExercises = getSortedExercises(
    exercises.filter((exercise) => {
      if (exercise && exercise.name && typeof exercise.name === "string") {
        return exercise.name.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false; // Filter out exercises without a valid name
    })
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Exercise Dashboard
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowAddForm(true)}
          >
            <FaPlusCircle className="mr-2" />
            Add Exercise
          </button>
        </div>
      </div>

      {showAddForm && (
        <AddForm onSave={handleSave} onCancel={() => setShowAddForm(false)} />
      )}
      {showEditForm && (
        <EditForm
          formData={currentExercise}
          onSave={(updatedExercise) => {
            setExercises(
              exercises.map((exercise) =>
                exercise.id === updatedExercise.id ? updatedExercise : exercise
              )
            );
            setShowEditForm(false);
            setCurrentExercise(null);
          }}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
          token={token}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="sm:w-1/3 mb-4">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            id="search"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="sm:w-1/3 mb-4">
          <label htmlFor="sort" className="sr-only">
            Sort
          </label>
          <select
            id="sort"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={sortOrder}
            onChange={(e) => handleSortChange(e.target.value)}
          >
            <option value="asc">Name (A-Z)</option>
            <option value="desc">Name (Z-A)</option>
            <option value="oldest">Oldest</option>
            <option value="newest">Newest</option>
          </select>
        </div>

        <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Exercise Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sets Number
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredExercises && filteredExercises.length > 0 ? (
                  filteredExercises.map((exercise) => (
                    <tr key={exercise.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {exercise.name ? exercise.name : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {exercise.muscle ? exercise.muscle : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {exercise.sets ? exercise.sets : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {exercise.description ? exercise.description : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex">
                          <button
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => handleEditClick(exercise)}
                          >
                            <FaEdit />
                          </button>
                          <button
                            type="button"
                            className="text-red-600 hover:text-red-900 ml-4"
                            onClick={() => deleteExercise(exercise.id)}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No exercises found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exercises;
