import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Profile2 from "../assets/images/Profile2.png";
import orangefit2 from "../assets/images/orangefit2.png";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <header className="bg-[#202023] text-white py-6">
        <div className="flex justify-between  px-20 text-center items-center">
          <img src={orangefit2} alt="Orangefit Logo" className="w-24 md:w-48" />
          <img className="w-10 h-10" src={Profile2} alt="profile image" />
        </div>
      </header>

      <div className="min-h-screen flex flex-col items-center justify-center bg-[#151515]">
        <div className="w-full max-w-xl px-16 py-10 bg-[#202023] text-white rounded-2xl shadow-lg">
          <h2 className="text-3xl text-start font-bold mb-6">
            Login to your account
          </h2>
          <form>
            <div className="mb-4">
              <label
                className="block text-base font-medium mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 bg-[#202023]  border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform  border-4 border-transparent focus:outline-none focus:border-orange-500 "
                required
              />
            </div>
            <div className="mb-4 relative">
              <label
                className="block text-base font-medium mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter your password"
                className="w-full px-4 py-2 bg-[#202023]  border-gray-700 rounded-lg text-white transition duration-300 ease-in-out transform  border-4 border-transparent focus:outline-none focus:border-orange-500 "
                required
              />
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <FiEyeOff className="text-gray-400" />
                ) : (
                  <FiEye className="text-gray-400" />
                )}
              </div>
              {/* <div className="text-right mt-2">
                <a
                  href="#"
                  className="text-base text-orange-500 hover:underline"
                >
                  Forgot?
                </a>
              </div> */}
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-[#FF6400] text-white font-bold rounded-lg hover:bg-orange-600 transition duration-300"
            >
              Login now
            </button>
          </form>
          {/* <div className="mt-6 text-center">
            <p className="text-gray-400">
              Don't Have An Account?{" "}
              <a href="#" className="text-[#FF6400] hover:underline">
                Sign Up
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
