import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Users from "../pages/Dashboard/Users/Users";
import Meals from "../pages/Dashboard/Meals/Meals";
import Exercises from "../pages/Dashboard/Exercises/Exercises";
import Workouts from "../pages/Dashboard/Workouts/Workouts";
import PlanManagement from "../pages/Dashboard/Plans/PlanManagement";
import Landing from "../components/Landing";
import Login from "../pages/Login";
import UserDetails from "../pages/Dashboard/Users/UserDetails";
import Analytics from "../pages/Dashboard/Analytics/Analytics";

const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/*" element={<ProAdmin />}> */}
      <Route path="dashboard/analytics" element={<Analytics />} />

      <Route path="/dashboard" element={<Navigate to="/dashboard/users" />} />
      <Route path="dashboard/users" element={<Users />} />
      <Route path="dashboard/users/:id" element={<UserDetails />} />
      <Route path="dashboard/meals" element={<Meals />} />
      <Route path="dashboard/exercises" element={<Exercises />} />
      <Route path="dashboard/workouts" element={<Workouts />} />
      <Route path="dashboard/plan-management" element={<PlanManagement />} />
      {/* <Route path="dashboard/logout" element={<Logout />} /> */}
      {/* </Route> */}

      <Route path="/" element={<Navigate to="/landing" />} />
      <Route path="landing" element={<Landing />} />

      <Route path="login" element={<Login />} />
    </Routes>
  );
};

export default Routers;
