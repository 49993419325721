import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { IoCheckbox } from "react-icons/io5";
import { ImCheckboxUnchecked } from "react-icons/im";
import { FiPlus } from "react-icons/fi";
import WorkoutDetails from "./WorkoutDetails";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [userMeal, setUserMeal] = useState([]);
  const [userWorkout, setUserWorkout] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [showDetails, setShowDetails] = useState(false);
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchUser(), fetchUserMeal(), fetchUserWorkout()]);
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUser(response.data.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const fetchUserMeal = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}/meals`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserMeal(response.data.data);
    } catch (error) {
      console.error("Error fetching user meals:", error);
    }
  };

  const fetchUserWorkout = async () => {
    try {
      const response = await axios.get(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}/workouts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserWorkout(response.data.data);
    } catch (error) {
      console.error("Error fetching user meals:", error);
    }
  };

  if (loading) {
    return <Loader />; // Show loader while data is being fetched
  }

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="flex items-center mb-8">
        <div className="w-16 h-16 bg-gray-300 rounded-full mr-4"></div>
        <div>
          <h2 className="text-3xl font-bold">{user?.name || "N/A"}</h2>
          <p className="text-gray-600">{user?.email || "N/A"}</p>
        </div>
      </div>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Personal Details:</h3>
        <div className="grid grid-cols-4 gap-8">
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Sex:
            </label>
            <input
              value={user?.sex || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Phone:
            </label>
            <input
              value={user?.phone || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Instagram Account:
            </label>
            <input
              value={user?.instagram_account || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Birth Date:
            </label>
            <input
              value={user?.birth_date || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Country:
            </label>
            <input
              value={user?.country || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              City:
            </label>
            <input
              value={user?.city || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Location:
            </label>
            <input
              value={user?.location || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Goal:
            </label>
            <input
              value={user?.goal || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Weight:
            </label>
            <input
              value={user?.weight || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Target Weight:
            </label>
            <input
              value={user?.target_weight || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Height:
            </label>
            <input
              value={user?.height || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Activity Level:
            </label>
            <input
              value={user?.activity_level || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Previous Diet Status:
            </label>
            <input
              value={user?.previous_diet_status || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Has Allergies:
            </label>
            <input
              value={user?.has_allergies || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Allergies Details:
            </label>
            <input
              value={user?.allergies_details || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Diseases:
            </label>
            <input
              value={user?.diseases || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Smoking Status:
            </label>
            <input
              value={user?.smoking_status || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Alcohol Status:
            </label>
            <input
              value={user?.alcohol_status || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Sports Practiced:
            </label>
            <input
              value={user?.sports_practiced || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Uses Protein Supplement:
            </label>
            <input
              value={user?.uses_protein_supplement || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Favorite Carbohydrate:
            </label>
            <input
              value={user?.favorite_carbohydrate || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Favorite Protein:
            </label>
            <input
              value={user?.favorite_protein || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Favorite Fats:
            </label>
            <input
              value={user?.favorite_fats || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Registration Complete:
            </label>
            <input
              value={user?.registration_complete || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Onboarding Current Step:
            </label>
            <input
              value={user?.onboarding_current_step || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Requested Plan:
            </label>
            <input
              value={user?.requested_plan || "N/A"}
              disabled
              className="input-field"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold text-gray-600 mb-1">
              Has Active Subscription:
            </label>
            <input
              value={user?.has_active_subscription || "N/A"}
              disabled
              className="input-field"
            />
          </div>
        </div>
      </section>
      <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
        <p className="font-semibold text-3xl mb-4"> Meals Schedule</p>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Meal Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Meal Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Checked
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userMeal.length > 0 ? (
                  userMeal.map((meal, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {meal.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.meal_type}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.pivot.meal_date}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.pivot.consumed_at == null ? (
                          <ImCheckboxUnchecked />
                        ) : (
                          <IoCheckbox />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No meals found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8 mt-10">
        <p className="font-semibold text-3xl mb-4"> Workouts Schedule</p>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 text-center">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Meal Type
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Workout Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Exercises
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userWorkout.length > 0 ? (
                  userWorkout.map((workout, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {workout.title}
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {workout.meal_type}
                      </td> */}
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {workout.pivot.workout_schedule_date}
                      </td>
                      {/* <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => setShowDetails(true)}
                      >
                        <FiPlus className="w-8 h-8" />
                      </td> */}
                      <td>
                        <WorkoutDetails
                          workout={workout}
                          // onCancel={() => setShowDetails(false)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No workout found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

{
  /* <div className="grid grid-cols-6 gap-4">
<p>
  sex: <br />
  <input
    value={user?.sex || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  phone: <br />
  <input
    value={user?.phone || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  instagram account: <br />
  <input
    value={user?.instagram_account || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  birth date: <br />
  <input
    value={user?.birth_date || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  country: <br />
  <input
    value={user?.country || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  city: <br />
  <input
    value={user?.city || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  location: <br />
  <input
    value={user?.location || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  goal: <br />
  <input
    value={user?.goal || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  weight: <br />
  <input
    value={user?.weight || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  target weight: <br />
  <input
    value={user?.target_weight || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  height: <br />
  <input
    value={user?.height || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  activity level: <br />
  <input
    value={user?.activity_level || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  previous diet status: <br />
  <input
    value={user?.previous_diet_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  has allergies: <br />
  <input
    value={user?.has_allergies || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  allergies details: <br />
  <input
    value={user?.allergies_details || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  diseases: <br />
  <input
    value={user?.diseases || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  smoking status: <br />
  <input
    value={user?.smoking_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  sports practiced: <br />
  <input
    value={user?.sports_practiced || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  uses protein supplement: <br />
  <input
    value={user?.uses_protein_supplement || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  favorite carbohydrate: <br />
  <input
    value={user?.favorite_carbohydrate || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  favorite protein: <br />
  <input
    value={user?.favorite_protein || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  favorite fats: <br />
  <input
    value={user?.favorite_fats || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  registration complete: <br />
  <input
    value={user?.registration_complete || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  onboarding current step: <br />
  <input
    value={user?.onboarding_current_step || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  requested plan: <br />
  <input
    value={user?.requested_plan || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  has active subscription: <br />
  <input
    value={user?.has_active_subscription || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
<p>
  alcohol_status: <br />
  <input
    value={user?.alcohol_status || "N/A"}
    disabled
    className="p-2 border border-gray-300 rounded-md"
  />
</p>
</div> */
}
