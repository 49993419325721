import React, { useEffect, useState } from "react";
import { FaHome, FaUser, FaCog, FaBars } from "react-icons/fa";
import CardDataStats from "./CardDataStats";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import ChartCircle from "./ChartCircle";
import Chart from "./Chart";

const Analytics = () => {
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true); // Added loading state
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    const fetchData = async () => {
      await fetchStatus();
      setLoading(false); // Set loading to false after data is fetched
    };
    fetchData();
    // fetchStatus();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/reports/subscription-status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      setStatus(response.data.data);
      //   console.log(status);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  if (loading) {
    return <Loader />; // Show loader while data is being fetched
  }
  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5 mt-10">
        <CardDataStats title="Total Subscriptions" total={status.users_count}>
          <FaUser />
        </CardDataStats>
        <CardDataStats
          title="Active Subscriptions"
          total={status.users_with_active_subscriptions}
        >
          <FaUser />
        </CardDataStats>
        <CardDataStats
          title="Renewed Subscriptions"
          total={status.users_with_renewed_subscriptions}
        >
          <FaUser />
        </CardDataStats>
      </div>
      <div className="mt-2 grid grid-cols-9 gap-4 md:mt-6 md:gap-2 2xl:mt-28 2xl:gap-7.5">
        <ChartCircle />
        <Chart />
      </div>
      <div className="mb-28"></div>
      <div></div>
    </>
  );
};

export default Analytics;
