import React from "react";

const PricingCard = ({ title, price, isPopular }) => {
  return (
    <div className="relative p-6 bg-[#1C1B1E] rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:border-orange-500 border-4 border-transparent  w-[335px] ">
      {isPopular && (
        <div className=" absolute w-full rounded-t-lg h-14 -top-1 -left-0 bg-orange-500 text-white text-2xl font-bold pt-2">
          Most popular
        </div>
      )}
      <div className="text-center ">
        <h3 className="text-white text-2xl font-semibold mt-10">{title}</h3>

        <p className="text-orange-400 text-xl">/user/mo</p>
        <div className="text-white text-[55px] font-bold py-4 relative">
          <span className="absolute text-[30px] top-4 left-[90px]">$</span>
          {price}
        </div>
        <button className="bg-orange-500 text-white w-[212px] h-[59px] text-[20px] px-4 py-2 rounded-lg">
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default PricingCard;
