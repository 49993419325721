import React, { useState, useEffect } from "react";
import ViewPlans from "./ViewPlans";
import axios from "axios";

const PlanManagement = () => {
  const [plans, setPlans] = useState([]);
  const [editMode, setEditMode] = useState({});
  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/plans?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const plansData = response.data.data;
      if (Array.isArray(plansData)) {
        setPlans(plansData);
      } else {
        console.error("Unexpected response format:", plansData);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const toggleEditMode = (planId) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [planId]: !prevEditMode[planId],
    }));
  };

  return (
    <div className=" p-6 border rounded shadow">
      <ViewPlans
        plans={plans}
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        setPlans={setPlans}
        token={token}
      />
    </div>
  );
};

export default PlanManagement;
