import React, { useState } from "react";
import Profile from "../assets/images/user-circle-single--circle-geometric-human-person-single-user.png";
import phoneImage from "../assets/images/Device 14PM.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import phone1 from "../assets/images/phone1.png";
import phone2 from "../assets/images/phone2.png";
import googleplay from "../assets/images/googleplay.png";
import appstore from "../assets/images/appstore.png";
import orangfit from "../assets/images/orangfit.png";
import girl1 from "../assets/images/girl1.png";
import girl2 from "../assets/images/girl2.png";
import man1 from "../assets/images/man1.png";
import man2 from "../assets/images/man2.png";
import circles from "../assets/images/circles.png";
import orangefit2 from "../assets/images/orangefit2.png";

import facebook from "../assets/images/facebook.png";
import instalgram from "../assets/images/instalgram.png";
import youtube from "../assets/images/youtube.png";
import gmail from "../assets/images/gmail.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "tailwindcss/tailwind.css";
import "./TextSlider.css";
import PricingCard from "./PricingCard";
import FAQItem from "./FAQItem";

const Landing = () => {
  const [percentage, setPercentage] = useState(25);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter2 = () => {
    const animateProgress = () => {
      let start = 25; // initial value
      const end = 95; // final value
      const duration = 2000; // duration in ms
      const increment = (end - start) / (duration / 16); // increment per frame (assuming 60fps)

      const interval = setInterval(() => {
        start += increment;
        if (start >= end) {
          start = end;
          clearInterval(interval);
        }
        setPercentage(start);
      }, 16); // update every ~16ms (approx. 60fps)

      return () => clearInterval(interval);
    };

    animateProgress();
  };

  const [stats, setStats] = useState({
    clients: 0,
    results: 0,
    workouts: 0,
    recipes: 0,
  });

  const handleMouseEnter = () => {
    const targetStats = {
      clients: 1200,
      results: 110,
      workouts: 300,
      recipes: 500,
    };

    let intervalId = null;
    const duration = 1000; // duration of the animation in ms
    const steps = 10; // total animation frames
    const stepTime = duration / steps;

    intervalId = setInterval(() => {
      setStats((prevStats) => {
        const newStats = { ...prevStats };
        let done = true;

        for (let key in targetStats) {
          if (newStats[key] < targetStats[key]) {
            newStats[key] += Math.ceil(targetStats[key] / steps);
            done = false;
          }
        }

        if (done) {
          clearInterval(intervalId);
          return targetStats;
        }

        return newStats;
      });
    }, stepTime);
  };

  const [visibleImage, setVisibleImage] = useState([]);

  const handleMouseEnter3 = (index) => {
    if (!visibleImage.includes(index)) {
      setVisibleImage([...visibleImage, index]);
    }
  };

  const testimonials = [
    {
      title: "10 kgs in 3 weeks",
      text: "“I tried all the diets in the world and failed, Even when I lose weight, I gain it back because the diets were unsustainable. Thank god I discovered orangefit.”",
      author: "Anshuman Khuranna",
    },
    {
      title: "5 kgs in 2 weeks",
      text: "“Orangefit helped me lose weight in a sustainable way. I've never felt better!”",
      author: "John Doe",
    },
    // Add more testimonials as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="custom-dot">
        <div className="dot"></div>
      </div>
    ),
  };

  return (
    <div className="font-sans bg-[#151515]">
      {/* Header */}
      <header className="bg-[#FF6400] text-white py-6">
        <div className="flex justify-between  px-20 text-center items-center">
          <img src={orangfit} alt="Orangefit Logo" className="w-24 md:w-48" />
          <img className="w-10 h-10" src={Profile} alt="profile image" />
        </div>
      </header>

      {/* Hero Section */}
      <section className="relative overflow-hidden bg-[#FF6400] text-white">
        <div className="container mx-auto px-4 py-20 flex flex-col items-center md:flex-row md:justify-between">
          <div className="text-center md:text-left z-10">
            <h1 className="text-3xl md:text-7xl font-bold mb-6">
              Reach your dream body
            </h1>
            <h2 className="text-3xl md:text-7xl mb-12">with Dr. Michel Adib</h2>
            <p className="mb-12 text-xl md:text-2xl">
              Orangefit was made because you deserve the best.
            </p>
            <div className="flex flex-col md:flex-row justify-center items-center md:justify-start space-y-4 md:space-y-0 md:space-x-4">
              <button className="relative z-20 bg-white text-[#FF6400] text-xl font-bold py-5 w-64 md:w-72 rounded-3xl shadow-2xl hover:opacity-90">
                START TODAY
              </button>
              <button className="relative z-20 bg-white text-[#FF6400] text-xl font-bold py-5 w-64 md:w-72 rounded-3xl shadow-2xl hover:opacity-90">
                DOWNLOAD APP
              </button>
            </div>
          </div>
          <div className="z-10 mt-12 md:mt-0">
            <img
              src={phoneImage}
              alt="Mobile App"
              className="block mx-auto relative z-10 w-60 md:w-auto"
            />
          </div>
          <div className="absolute z-0 -bottom-16 left-0 w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="w-full h-full"
            >
              <path
                fill="#151515"
                fillOpacity="1"
                d="M0,192L60,192C120,192,240,192,360,186.7C480,181,600,171,720,154.7C840,139,960,117,1080,112C1200,107,1320,117,1380,122.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
              ></path>
            </svg>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-[#151515] text-white py-40">
        <div className="mx-auto text-center" onMouseEnter={handleMouseEnter}>
          <h2 className="text-[#FF6400] text-6xl font-bold mb-32">
            What We've Been Up To ...
          </h2>
          <div className="grid md:grid-cols-4 gap-10  ">
            <div>
              <p className="text-7xl font-bold">{stats.clients}+</p>
              <p>Happy Clients</p>
            </div>
            <div>
              <p className="text-7xl font-bold">{stats.recipes}%</p>
              <p>Guaranteed Results</p>
            </div>
            <div>
              <p className="text-7xl font-bold">{stats.workouts}K+</p>
              <p>Workouts</p>
            </div>
            <div>
              <p className="text-7xl font-bold">{stats.results}+</p>
              <p>Healthy Recipes</p>
            </div>
          </div>
        </div>
      </section>

      {/* Quote Section */}
      <section className="bg-[#FF6400] text-white pb-20 pt-24 rounded-2xl md:rounded-none">
        <div
          className="flex flex-col md:flex-row w-full justify-evenly mx-auto px-4 items-center text-center"
          onMouseEnter={handleMouseEnter2}
        >
          <div>
            <p className="text-4xl text-center md:text-6xl mb-6 md:mb-14 pt-16 md:text-start leading-tight">
              <span className="font-bold">“95%</span> of individuals who try
              <br />
              to lose weight
              <span className="font-bold"> fail”</span>
            </p>
            <p className="text-2xl md:text-2xl md:font-bold font-normal text-center md:text-start pl-6">
              You don't deserve that, you deserve orabgefit.
            </p>
          </div>

          <div className="mt-10 md:mt-0 w-96 md:w-80 h-90">
            <CircularProgressbar
              value={percentage}
              text={`${Math.round(percentage)}%`}
              styles={buildStyles({
                textSize: "20px",
                pathColor: "#fff",
                textColor: "#fff",
                trailColor: "#000",
                backgroundColor: "#ff7f00",
              })}
              className="mb-6 md:mb-14"
            />
            <p className="text-xl md:text-3xl">Don't Be a Statistic</p>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-[#151515] text-white py-20">
        <h2 className="text-[#FF6400] text-4xl md:text-5xl text-center font-bold mb-20">
          Why choose orangefit ?
        </h2>
        {/* Feature 1 */}
        <div className="flex flex-col-reverse text-center md:text-start justify-evenly md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 animate-fadeIn">
          <div className="relative w-4/5 md:w-1/3 md:left-24">
            <img
              src={image1}
              alt="Feature 1"
              className=" rounded-md shadow-lg "
            />
          </div>
          <div className="relative  bottom-16">
            <h2 className="text-[70px] font-bold text-[#FF6400] mb-4">1</h2>
            <h3 className="text-[40px] font-bold mb-8">
              <p>Weekly customized</p>
              <p>meal plan for you</p>
            </h3>
            <div className="text-[24px]">
              <p className="mb-1">Different day = different meals</p>
              <p className="mb-1">(250+ healthy recipes with full</p>
              <p className="mb-1">instructions)</p>
              <p>Who said dieting is boring!</p>
            </div>
          </div>
        </div>
        {/* Feature 2 */}
        <div
          className="flex flex-col-reverse justify-evenly text-center  md:flex-row-reverse items-center space-y-44 md:space-y-0 md:space-x-6 animate-fadeIn"
          onMouseEnter={() => handleMouseEnter3(2)}
        >
          <div className="relative w-4/5 md:w-1/3 bottom-16 md:bottom-24 md:right-14">
            <img
              src={image2}
              alt="Feature 2"
              className={` rounded-md shadow-lg transition-opacity duration-500 ${
                visibleImage.includes(2) ? "opacity-100" : "opacity-0"
              }`}
            />
          </div>

          <div className="relative md:left-16 bottom-28 md:text-start">
            <h2 className="text-[70px] md:text-right font-bold text-[#FF6400] ">
              2
            </h2>
            <h3 className="text-[40px] font-bold mb-3">
              <p>Customized workout</p>
              <p className="md:text-right">plan for you</p>
            </h3>
            <div className="text-[24px] md:text-right">
              <p className="mb-1">(500+ instructional workouts</p>
              <p className="mb-1"> videos by Dr. Michel, to guarantee</p>
              <p className="mb-1">you are performing the right</p>
              <p>technique)</p>
            </div>
          </div>
        </div>
        {/* Feature 3 */}
        <div
          className="flex flex-col-reverse justify-evenly md:flex-row text-center items-center space-y-44 md:space-y-0 md:space-x-6 animate-fadeIn"
          onMouseEnter={() => handleMouseEnter3(3)}
        >
          <div className="relative w-4/5 md:w-1/3 bottom-24 md:bottom-40 md:left-32">
            <img
              src={image3}
              alt="Feature 1"
              className={` rounded-md shadow-lg transition-opacity duration-500 ${
                visibleImage.includes(3) ? "opacity-100" : "opacity-0"
              }`}
            />
          </div>
          <div className="relative  bottom-36">
            <h2 className="text-[70px] font-bold text-[#FF6400] mb-4">3</h2>
            <h3 className="text-[40px] font-bold mb-6">
              <p> Daily Weight tracking,</p>
              <p>and monthly check-ups</p>
              <p>from the expert</p>
              <p>himself !</p>
            </h3>
            <div className="text-[24px]">
              <p className="mb-1">To make sure you are on the right</p>
              <p className="mb-1"> track.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#FF6400] rounded-xl text-center text-white">
        <div className="flex flex-col md:flex-row space-y-44 justify-around pb-28 md:mb-0">
          <div className="text-2xl md:text-[60px] relative top-36 font-bold">
            <p className=" md:mb-10 text-center md:text-start">
              Level Up Your Fitness
            </p>
            <p className=" md:mb-10 text-center md:text-start">Journey With </p>
            <p className=" md:mb-16 mb-6 text-center md:text-start">
              Orangefit App.
            </p>
            <p className="mb-6 md:text-[25px] text-[20px] md:font-bold font-normal">
              The only app you will need to reach your dream body.
            </p>
            <div className="flex justify-center md:justify-start space-x-4">
              <img src={googleplay} alt="Google Play" className="w-[194px] " />
              <img src={appstore} alt="App Store" className="w-[186px]" />
            </div>
          </div>

          <div className="relative z-10 md:right-40 lg:right-36 xl:right-28 2xl:right-28">
            <img
              src={phone1}
              alt="phone1"
              className="w-48 md:w-auto md:h-auto left-1/4 relative z-10"
            />
            <img
              className="absolute z-0 top-12 left-2/4 md:top-24 md:left-64 w-40 md:w-auto md:h-auto"
              src={phone2}
              alt="phone2"
            />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-[#151515] text-white pt-20 mb-80">
        <div className=" mx-auto px-4 text-center">
          <h2 className="text-5xl text-[#FF6400] leading-tight font-bold mb-16">
            Orangefit is the best,
            <br /> Here’s the proof
          </h2>
          <div className="md:flex flex-row justify-around">
            <div className="flex flex-col  relative">
              <div className="flex flex-row">
                <img className="w-[123px] h-[230px]" src={girl1} alt="error" />
                <img className="w-[123px] h-[230px]" src={girl2} alt="error" />
                <img className="w-[123px] h-[230px]" src={man1} alt="error" />
              </div>
              <div className=" flex flex-row">
                <img className="w-[123px] h-[230px]" src={man1} alt="error" />
                <img className="w-[123px] h-[230px]" src={girl1} alt="error" />
                <img className="w-[123px] h-[230px]" src={girl2} alt="error" />
              </div>
            </div>
            <div>
              <div className="bg-[#151515] text-white py-16 px-8 flex justify-center">
                <div className="md:max-w-lg max-w-sm text-center">
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="px-4">
                        <h3 className="text-3xl font-bold mb-4">
                          {testimonial.title}
                        </h3>
                        <p className="mb-10">{testimonial.text}</p>
                        <p className="text-xl font-semibold mb-10">
                          {testimonial.author}
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="hidden md:flex">
              <div className="flex flex-col  relative">
                <div className="flex flex-row">
                  <img
                    className="w-[123px] h-[230px]"
                    src={girl1}
                    alt="error"
                  />
                  <img
                    className="w-[123px] h-[230px]"
                    src={girl2}
                    alt="error"
                  />
                  <img className="w-[123px] h-[230px]" src={man1} alt="error" />
                </div>
                <div className=" flex flex-row">
                  <img className="w-[123px] h-[230px]" src={man1} alt="error" />
                  <img
                    className="w-[123px] h-[230px]"
                    src={girl1}
                    alt="error"
                  />
                  <img
                    className="w-[123px] h-[230px]"
                    src={girl2}
                    alt="error"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Expert Section */}

      <section className="bg-[#FD7B03] text-white pt-20 pb-80">
        <div className="relative bg-[#1a1a1a] h-0 -bottom-[340px] sm:-bottom-36 xl:-bottom-40 2xl:-bottom-48">
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFAA4C"
              fillOpacity="1"
              d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,192C840,203,960,213,1080,208C1200,203,1320,181,1380,176L1440,171L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FD7B03"
              fillOpacity="1"
              d="M0,288L60,272C120,256,240,224,360,224C480,224,600,256,720,266.7C840,277,960,267,1080,245.3C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div
          className="md:flex flex-row relative"
          onMouseEnter={() => setIsHovered(true)}
        >
          <div className=" text-center md:text-start">
            <div className="flex relative">
              <div className="bg-[#FFA451] rounded-r-xl pt-5 md:pl-32 pl-16 pr-2">
                <h2 className="text-2xl md:text-4xl font-bold mb-4">
                  Meet our expert
                </h2>
                <h2 className="text-2xl md:text-7xl font-bold mb-4 text-end">
                  Dr. MICHEL
                </h2>
              </div>
              <div>
                <h2 className="text-2xl md:text-7xl font-bold absolute bottom-4 ml-2">
                  ADIB
                </h2>
              </div>
            </div>
            <div className="md:ml-32 ml-0">
              <p className="text-2xl font-bold mt-12">My Philosophy</p>

              <div
                className={`transition-opacity duration-500 ${
                  isHovered ? "opacity-100" : "opacity-0"
                }`}
              >
                <p className="text-3xl font-bold mt-6 leading-snug">
                  “My goal is to let everybody get the healthy
                </p>
                <p className="text-3xl font-bold leading-snug">
                  life they deserve , no matter where they are
                </p>
                <p className="text-3xl font-bold leading-snug">
                  from or how much money they have”
                </p>
                <p className="text-xl  mt-8">I know how much you suffered ,</p>
                <p className="text-xl ">I know how many diets you tried,</p>
                <p className="text-xl ">
                  to reach the body you deserve and failed,
                </p>
                <p className="text-xl ">The problem is not you,</p>
                <p className="text-xl ">
                  The problem is the boring food , hunger in the diets you
                  chose.
                </p>
                <p className="text-xl font-bold">Orangefit is the solution .</p>
                <p className="text-base mt-3 opacity-60">
                  Michel Adib , Founder of orangefit.
                </p>
              </div>

              <button className="bg-white hover:opacity-85 mt-12 text-[#FF6400] text-xl font-bold py-5 w-64 md:min-w-72 rounded-3xl shadow-2xl">
                START TODAY
              </button>
            </div>
          </div>
          <div className="bg-[#FD7B03] p-8 rounded-lg z-0 mb-72 ">
            <div className=" md:w-[483px] w-96 absolute right-6 md:right-0 md:top-24">
              <Slider {...settings}>
                <div className="">
                  <img src={man2} alt="Slide 1" className="rounded-lg" />
                </div>
                <div className="">
                  <img src={man2} alt="Slide 1" className="rounded-lg" />
                </div>
                <div className="">
                  <img src={man2} alt="Slide 1" className="rounded-lg" />
                </div>
              </Slider>
            </div>
            <img src={circles} className=" -z-10 absolute top-0 right-0" />
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="bg-[#151515] text-white py-20">
        <div className="relative bg-[#1a1a1a] h-0 md:-bottom-48 -bottom-[344px]">
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFAA4C"
              fillOpacity="1"
              d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,192C840,203,960,213,1080,208C1200,203,1320,181,1380,176L1440,171L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
          <svg
            className="absolute bottom-0 w-full h-screen"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#151515"
              fillOpacity="1"
              d="M0,288L60,272C120,256,240,224,360,224C480,224,600,256,720,266.7C840,277,960,267,1080,245.3C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-5xl font-bold mb-20">
            Choose a plan that’s right for you
          </h2>

          <div className="flex-row md:flex ml-8 space-y-12 md:space-y-0 md:space-x-16">
            <PricingCard title="Diet only" price="25" features="Diet only" />
            <PricingCard
              title="Diet + Workout"
              price="19"
              features="Diet + Workout"
              isPopular
            />
            <PricingCard
              title="Workout only"
              price="25"
              features="Workout only"
            />
          </div>
        </div>
      </section>
      <div className="w-9/12 mx-auto mt-20 mb-36 h-1 bg-gradient-to-r from-transparent via-orange-500 to-transparent "></div>
      {/* FAQ Section */}
      <div className="bg-[#151515] text-white h-96 flex flex-col items-center py-10">
        <div className="flex flex-col-reverse md:flex-row justify-evenly w-full px-6">
          {/* Left Section: FAQ */}
          <div className="w-full md:w-2/5 space-y-8">
            <FAQItem
              question="How do I sign up for the project?"
              answer="Yes! If you're looking for a free calorie counter app, you're in the right place. Simply sign up for your free account here and start tracking your food. The MyFitnessPal app does a lot more than track calories in foods. You can also track macros, vitamins, and other micronutrients and see how everything you eat supports your goals."
            />
            <FAQItem
              question="What thing that I should prepare before starting?"
              answer="Some general information about preparations you might need before starting."
            />
            <FAQItem
              question="Does my company need help for marketing advice?"
              answer="Information about whether your company might need help with marketing advice."
            />
            <div className="border-t border-orange-500 pt-8"></div>
          </div>

          {/* Right Section: Contact */}
          <div className="w-full md:w-1/3 mb-20 md:mb-20">
            <h2 className="text-6xl font-semibold mb-4">
              How We Can Help You?
            </h2>
            <p className="text-2xl font-semibold text-gray-400 mb-8 mt-8">
              Follow our newsletter. We will regularly <br /> update our latest
              project and <br /> availability.
            </p>
            <div className="flex space-x-2">
              <input
                type="email"
                placeholder="Enter Your Email"
                className="w-80 pl-4 py-4 rounded-full border border-gray-400 focus:outline-none"
              />
              <button className="bg-[#FD7B03] text-white px-6 py-2 w-36 rounded-full">
                Let's Talk
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-[#151515]  text-white py-10 md:mt-48 mt-[500px]">
        <div className="  mt-4 md:flex flex-row text-center justify-evenly items-center  pb-4">
          <div className="flex-col space-y-8 flex justify-between items-center">
            <div className="space-x-2">
              <img src={orangefit2} />
            </div>
            <button className="bg-[#F6FFF8] text-[#FF6400] w-56 h-14 font-bold text-lg rounded-full">
              START TODAY
            </button>
          </div>
          <div>
            <a href="#" className="text-2xl text-[#F6FFF8]">
              About us
            </a>
            <div className="text-[#E0E0E0] mt-6 text-base">
              Terms & conditions
            </div>
          </div>
          <div>
            <a href="#" className="text-2xl text-[#F6FFF8] opacity-90">
              Features
            </a>
            <div className="text-[#E0E0E0] mt-6 text-base">Privacy policy</div>
          </div>
          <div>
            <a href="#" className="text-2xl text-[#F6FFF8]">
              Food
            </a>
            <div className="text-[#E0E0E0] mt-6 text-base">Contact</div>
          </div>
          <div>
            <a href="#" className="text-2xl text-[#F6FFF8]">
              Reviews
            </a>
            <div className="text-[#E0E0E0] mt-6 text-base">Cookie policy</div>
          </div>
          <div>
            <a href="#" className="text-2xl text-[#F6FFF8]">
              Sign in
            </a>
            <div className="text-[#E0E0E0] mt-6 text-base">Support</div>
          </div>
        </div>
        <div className="w-9/12 mx-auto mt-2 mb-8 h-1 bg-gradient-to-r from-transparent via-white to-transparent "></div>

        <div className="container mx-auto mt-4 flex justify-center space-x-8">
          <a href="#" className="text-orange-500">
            <img src={facebook} />
          </a>
          <a href="#" className="text-orange-500">
            <img src={instalgram} />
          </a>
          <a href="#" className="text-orange-500">
            <img src={youtube} />
          </a>
          <a href="#" className="text-orange-500">
            <img src={gmail} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
