import React, { useState, useEffect } from "react";
import axios from "axios";
import AddWorkouts from "./AddWorkouts";
import AddMeals from "./AddMeals";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [showWorkoutsSchedule, setShowWorkoutsSchedule] = useState(false);
  const [showMealsSchedule, setShowMealsSchedule] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();

  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/users?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(
        `https://api.drmicheladib.com/api/v1/admin/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const getSortedUsers = (users) => {
    let sortedUsers = [...users];

    if (sortOrder === "asc") {
      sortedUsers.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === "desc") {
      sortedUsers.sort((a, b) => b.name.localeCompare(a.name));
    } else if (sortOrder === "oldest") {
      sortedUsers.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedUsers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedUsers;
  };

  const filteredUsers = getSortedUsers(
    users.filter((user) =>
      user.name?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-semibold text-gray-900">Users Dashboard</h1>

      {showWorkoutsSchedule && (
        <AddWorkouts
          CloseWorkoutsSchedule={() => setShowWorkoutsSchedule(false)}
        />
      )}
      {showMealsSchedule && (
        <AddMeals CloseMealsSchedule={() => setShowMealsSchedule(false)} />
      )}

      <div className="mt-8 mb-6 flex flex-col md:space-x-8 md:flex-row">
        <div className="flex space-x-8 w-2/3">
          <div className="sm:w-1/3 mb-4">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <input
              type="text"
              id="search"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search by Name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="sm:w-1/3 mb-4">
            <label htmlFor="sort" className="sr-only">
              Sort
            </label>
            <select
              id="sort"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="asc">Name (A-Z)</option>
              <option value="desc">Name (Z-A)</option>
              <option value="oldest">Oldest</option>
              <option value="newest">Newest</option>
            </select>
          </div>
        </div>

        <div className="flex space-x-8 flex-row">
          <button
            type="button"
            className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowWorkoutsSchedule(true)}
          >
            <FaPlusCircle className="mr-2" />
            Workouts Schedule
          </button>
          <button
            type="button"
            className="h-12 inline-flex items-center px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowMealsSchedule(true)}
          >
            <FaPlusCircle className="mr-2" />
            Meals Schedule
          </button>
        </div>
      </div>

      {filteredUsers.length > 0 ? (
        <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sex
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Weight (Kg)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Height (cm)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Birth Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredUsers.map((user) => (
                    <tr key={user.id}>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.name || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.email || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.phone || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.sex || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.weight || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.height || "N/A"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        onClick={() => navigate(`${user.id}`)}
                      >
                        {user.birth_date || "N/A"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          type="button"
                          className="text-red-600 hover:text-red-900 ml-4"
                          onClick={() => deleteUser(user.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Users;
