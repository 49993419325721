import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlusCircle, FaEdit, FaTrash } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";

const Workouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc', 'desc', 'oldest', 'newest'

  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchWorkouts();
  }, [workouts]);

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/workouts?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts(response.data.data);
    } catch (error) {
      console.error("Error fetching workouts:", error);
    }
  };

  const addWorkout = async (workout) => {
    try {
      const response = await axios.post(
        "https://api.drmicheladib.com/api/v1/admin/workouts", // Corrected URL
        workout,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts([...workouts, response.data]);
      setShowAddForm(false);
      return response; // Explicitly return the response
    } catch (error) {
      console.error("Error adding Workout:", error);
      throw error; // Rethrow the error to propagate it
    }
  };

  const updateWorkout = async (updatedWorkout) => {
    if (!updatedWorkout.id) {
      console.error("Updated Workout does not have an ID");
      return; // Optionally handle this case
    }

    try {
      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/workouts/${updatedWorkout.id}`,
        updatedWorkout,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setWorkouts(
        workouts.map((workout) =>
          workout.id === updatedWorkout.id ? updatedWorkout : workout
        )
      );
      setShowEditForm(false);
      setCurrentWorkout(null);
      return response; // Explicitly return the response
    } catch (error) {
      console.error("Error updating Workout:", error);
      throw error; // Rethrow the error to propagate it
    }
  };

  const handleSave = (updatedWorkout) => {
    updateWorkout(updatedWorkout);
  };

  const deleteWorkout = async (id) => {
    try {
      await axios.delete(
        `https://api.drmicheladib.com/api/v1/admin/workouts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWorkouts(workouts.filter((workout) => workout.id !== id));
    } catch (error) {
      console.error("Error deleting Workout:", error);
    }
  };

  const handleEditClick = (workout) => {
    setCurrentWorkout(workout);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentWorkout({ ...currentWorkout, [name]: value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const getSortedWorkouts = (workouts) => {
    let sortedWorkouts = [...workouts];

    if (sortOrder === "asc") {
      sortedWorkouts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOrder === "desc") {
      sortedWorkouts.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOrder === "oldest") {
      sortedWorkouts.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    } else if (sortOrder === "newest") {
      sortedWorkouts.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    }

    return sortedWorkouts;
  };

  // Filter workouts based on search query
  const filteredWorkouts = getSortedWorkouts(
    workouts.filter((workout) => {
      if (workout.title && typeof workout.title === "string") {
        return workout.title.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false; // Filter out workouts without a valid title
    })
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Workout Dashboard
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowAddForm(true)}
          >
            <FaPlusCircle className="mr-2" />
            Add Workout
          </button>
        </div>
      </div>

      {showAddForm && (
        <AddForm onSave={addWorkout} onCancel={() => setShowAddForm(false)} />
      )}
      {showEditForm && (
        <EditForm
          formData={currentWorkout}
          onSave={handleSave}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="sm:w-1/3 mb-4">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            id="search"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search by Title"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="sm:w-1/3 mb-4">
          <label htmlFor="sort" className="sr-only">
            Sort
          </label>
          <select
            id="sort"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={sortOrder}
            onChange={(e) => handleSortChange(e.target.value)}
          >
            <option value="asc">Title (A-Z)</option>
            <option value="desc">Title (Z-A)</option>
            <option value="oldest">Oldest</option>
            <option value="newest">Newest</option>
          </select>
        </div>

        <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tags
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Exercises
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredWorkouts && filteredWorkouts.length > 0 ? (
                    filteredWorkouts.map((workout) => (
                      <tr key={workout.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {workout.title ? workout.title : ""}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {workout.description ? workout.description : ""}
                        </td>

                        <td className="px-4 py-2">
                          {workout.tags && workout.tags.length > 0
                            ? workout.tags.map((tag, index) => (
                                <div key={index}>
                                  <span className="font-semibold">
                                    {tag.name ? tag.name : ""}
                                  </span>
                                </div>
                              ))
                            : ""}
                        </td>
                        <td className="px-4 py-2">
                          {workout.exercises && workout.exercises.length > 0
                            ? workout.exercises.map((exercise, index) => (
                                <div key={index}>
                                  <span className="font-semibold">
                                    {exercise.name ? exercise.name : ""}
                                  </span>
                                </div>
                              ))
                            : ""}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex">
                            <button
                              type="button"
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => handleEditClick(workout)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              type="button"
                              className="text-red-600 hover:text-red-900 ml-4"
                              onClick={() => deleteWorkout(workout.id)}
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                      >
                        No workouts found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
