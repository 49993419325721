import React, { useState } from "react";
import { FaHome, FaUser, FaBars } from "react-icons/fa";
import { GiMeal } from "react-icons/gi";
import { FaDumbbell } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import { LuCircleDollarSign } from "react-icons/lu";
import { MdLogout } from "react-icons/md";
import { NavLink } from "react-router-dom";

const adminNav = [
  { path: "/dashboard/analytics", display: "Analytics", icon: <FaHome /> },
  { path: "/dashboard/users", display: "Users", icon: <FaUser /> },
  { path: "/dashboard/meals", display: "Meals", icon: <GiMeal /> },
  { path: "/dashboard/exercises", display: "Exercises", icon: <FaDumbbell /> },
  { path: "/dashboard/workouts", display: "Workouts", icon: <FaRunning /> },
  {
    path: "/dashboard/plan-management",
    display: "Plan Management",
    icon: <LuCircleDollarSign />,
  },
  { path: "/logout", display: "Logout", icon: <MdLogout /> },
];

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`bg-gray-800 text-white min-h-screen ${
        isOpen ? "w-52" : "w-16"
      } transition-all duration-500 flex flex-col`}
    >
      <div className="flex items-center justify-between p-4">
        <h1 className={`text-xl font-bold ${isOpen ? "block" : "hidden"}`}>
          Dashboard
        </h1>
        <button onClick={toggleSidebar} className="focus:outline-none">
          <FaBars />
        </button>
      </div>
      <nav className="flex-1 overflow-y-auto">
        <ul className="admin__menu-list space-y-4 mt-4">
          {adminNav.map((item, index) => (
            <li className="admin__menu-item" key={index}>
              <NavLink
                to={item.path}
                className={(navClass) =>
                  navClass.isActive
                    ? "flex items-center p-4 bg-gray-700"
                    : "flex items-center p-4 hover:bg-gray-700"
                }
              >
                <span className="mr-4">{item.icon}</span>
                <span className={`${isOpen ? "block" : "hidden"}`}>
                  {item.display}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
