import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import Routers from "../../routers/Routers";

const Layout = () => {
  const location = useLocation();

  return (
    <div className="flex h-screen">
      {location.pathname.startsWith("/dashboard") && <Sidebar />}
      <div className="flex-1 overflow-y-auto">
        <Routers />
      </div>
    </div>
  );
};

export default Layout;
