import React from "react";
import EditPlans from "./EditPlans";

const ViewPlans = ({ plans, editMode, toggleEditMode, setPlans, token }) => {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-4 text-gray-500">Plan Management</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg shadow-md">
          <thead>
            <tr className="text-left border-b-2 border-gray-300">
              <th className="px-4 py-2">Plan Name</th>
              <th className="px-4 py-2">Price (USD)</th>
              <th className="px-4 py-2">Price (Syria, S.P)</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <React.Fragment key={plan.id}>
                <tr className="border-b border-gray-200">
                  <td className="px-4 py-2">{plan.name}</td>
                  <td className="px-4 py-2">{plan.price || ""} $</td>
                  <td className="px-4 py-2">{plan.syr_price || ""} S.P</td>
                  <td className="px-4 py-2">
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded"
                      onClick={() => toggleEditMode(plan.id)}
                    >
                      {editMode[plan.id] ? "Cancel" : "Edit"}
                    </button>
                  </td>
                </tr>
                {editMode[plan.id] && (
                  <tr className="border-b border-gray-200">
                    <td colSpan="4">
                      <EditPlans
                        plan={plan}
                        token={token}
                        setPlans={setPlans}
                        toggleEditMode={toggleEditMode}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewPlans;
