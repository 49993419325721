import React, { useState, useEffect } from "react";

const EditForm = ({ formData, onSave, onCancel }) => {
  const initialFormData = {
    id: null,
    title_en: "",
    title_ar: "",
    instructions_en: "",
    instructions_ar: "",
    meal_type: "",
    nutrition_data: { calories: "", carbs: "", protein: "", fats: "" },
    tags: formData.tags ? [...formData.tags] : [],
    ingredients: formData.ingredients ? [...formData.ingredients] : [],
  };

  const [formValues, setFormValues] = useState(initialFormData);

  useEffect(() => {
    if (formData) {
      setFormValues({
        id: formData.id || null,
        title_en: formData.title_en || "",
        title_ar: formData.title_ar || "",
        instructions_en: formData.instructions_en || "",
        instructions_ar: formData.instructions_ar || "",
        meal_type: formData.meal_type || "",
        nutrition_data: {
          calories: formData.nutrition_data?.calories || "",
          carbs: formData.nutrition_data?.carbs || "",
          protein: formData.nutrition_data?.protein || "",
          fats: formData.nutrition_data?.fats || "",
        },
        tags: formData.tags ? [...formData.tags] : [],
        ingredients: formData.ingredients ? [...formData.ingredients] : [],
      });
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name in formValues.nutrition_data) {
      setFormValues({
        ...formValues,
        nutrition_data: {
          ...formValues.nutrition_data,
          [name]: value,
        },
      });
    } else if (name.startsWith("tag_")) {
      const index = parseInt(name.split("_")[1]);
      const newTags = [...formValues.tags];
      newTags[index] = value;
      setFormValues({ ...formValues, tags: newTags });
    } else if (name.startsWith("ingredient_")) {
      const [_, index, field] = name.split("_");
      const newIngredients = [...formValues.ingredients];
      newIngredients[parseInt(index)][field] = value;
      setFormValues({ ...formValues, ingredients: newIngredients });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleAddTag = () => {
    setFormValues({
      ...formValues,
      tags: [...formValues.tags, ""],
    });
  };

  const handleRemoveTag = (index) => {
    const newTags = formValues.tags.filter((_, i) => i !== index);
    setFormValues({ ...formValues, tags: newTags });
  };

  const handleAddIngredient = () => {
    setFormValues({
      ...formValues,
      ingredients: [...formValues.ingredients, { name: "", quantity: "" }],
    });
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = [...formValues.ingredients];
    newIngredients.splice(index, 1);
    setFormValues({ ...formValues, ingredients: newIngredients });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await onSave({
        ...formValues,
        tags: formValues.tags.filter(
          (tag) => typeof tag === "string" && tag.trim() !== ""
        ),
        ingredients: formValues.ingredients.filter(
          (ingredient) =>
            ingredient.name.trim() !== "" && ingredient.quantity.trim() !== ""
        ),
      });
      console.log("Meal updated successfully");
    } catch (error) {
      console.error("Error updating meal:", error);
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-xl w-full mt-10">
        <form
          className="mt-5 space-y-6 max-h-[80vh] overflow-y-auto"
          onSubmit={handleSubmit}
        >
          <div className="rounded-md shadow-sm space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  English Title
                </h2>
                <input
                  id="title_en"
                  name="title_en"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  value={formValues.title_en}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Arabic Title
                </h2>
                <input
                  id="title_ar"
                  name="title_ar"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Title"
                  value={formValues.title_ar}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Meal Type
              </h2>
              <select
                id="meal_type"
                name="meal_type"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                value={formValues.meal_type}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Meal Type
                </option>
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Dinner">Dinner</option>
                <option value="Snack">Snack</option>
              </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Calories
                </h2>
                <input
                  id="calories"
                  name="calories"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Calories"
                  value={formValues.nutrition_data.calories}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Carbs
                </h2>
                <input
                  id="carbs"
                  name="carbs"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Carbs"
                  value={formValues.nutrition_data.carbs}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Protein
                </h2>
                <input
                  id="protein"
                  name="protein"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Protein"
                  value={formValues.nutrition_data.protein}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Fats
                </h2>
                <input
                  id="fats"
                  name="fats"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Fats"
                  value={formValues.nutrition_data.fats}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Tags
              </h2>
              {formValues.tags.map((tag, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`tag_${index}`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Tag Name"
                    value={tag.name}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveTag(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddTag}
              >
                Add Tag
              </button>
            </div>
            <div className="space-y-4">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Ingredients
              </h2>
              {formValues.ingredients.map((ingredient, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    name={`ingredient_${index}_name`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Ingredient Name"
                    value={ingredient.name}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    name={`ingredient_${index}_quantity`}
                    className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                    placeholder="Quantity"
                    value={ingredient.quantity}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="bg-red-500 text-white px-4 py-2 rounded-lg shadow hover:bg-red-600 focus:outline-none"
                    onClick={() => handleRemoveIngredient(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none"
                onClick={handleAddIngredient}
              >
                Add Ingredient
              </button>
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                English Instructions
              </h2>
              <textarea
                id="instructions_en"
                name="instructions_en"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                value={formValues.instructions_en}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Arabic Instructions
              </h2>
              <textarea
                id="instructions_ar"
                name="instructions_ar"
                rows="4"
                required
                className="block w-full rounded-md border-4 border-form-stroke bg-transparent py-3 px-5 text-white outline-none transition focus:border-primary active:border-primary disabled:bg-[#F5F7FD]"
                value={formValues.instructions_ar}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 focus:outline-none"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
