import React, { useState } from "react";
import { FaCircleChevronUp, FaCircleChevronDown } from "react-icons/fa6";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-t border-orange-500 pt-8">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggleFAQ}
      >
        <h3 className=" text-lg md:text-2xl font-semibold">{question}</h3>
        {isOpen ? (
          <FaCircleChevronUp className="h-6 w-6 text-orange-500" />
        ) : (
          <FaCircleChevronDown className="h-6 w-6 text-orange-500" />
        )}
      </div>
      {isOpen && <p className="text-sm md:text-md mt-2">{answer}</p>}
    </div>
  );
};

export default FAQItem;
