import Landing from "./components/Landing";
import MultiSelect from "./components/MultiSelect";
import SignUpHeader from "./components/SignUpHeader";
import Meals from "./pages/Dashboard/Meals/Meals";
import Exercises from "./pages/Dashboard/Exercises/Exercises";
import PlanManagement from "./pages/Dashboard/Plans/PlanManagement";
import ViewPlans from "./pages/Dashboard/Plans/ViewPlans";
import Workouts from "./pages/Dashboard/Workouts/Workouts";
import Login from "./pages/Login";
import CreateSignUp from "./pages/sign-up/CreateSignUp";
import Step1 from "./pages/sign-up/Step1";
import Step2 from "./pages/sign-up/Step2";
import Step3 from "./pages/sign-up/Step3";
import Step4 from "./pages/sign-up/Step4";
import Step5 from "./pages/sign-up/Step5";
import Users from "./pages/Dashboard/Users/Users";
import Sidebar from "./components/Sidebar";
import Layout from "./components/Layout/Layout";
import UserDetails from "./pages/Dashboard/Users/UserDetails";
function App() {
  return (
    <>
      {/* <Landing /> */}
      {/* <CreateSignUp /> */}
      {/* <SignUpHeader /> */}
      {/* <Step1 /> */}
      {/* <Step2 /> */}
      {/* <Step3 /> */}
      {/* <Step4 /> */}
      {/* <Step5 /> */}
      {/* <Login /> */}
      {/* <MultiSelect /> */}
      {/* <Meals /> */}
      {/* <Exercises /> */}
      {/* <PlanManagement /> */}
      {/* <Workouts /> */}
      {/* <Users /> */}
      {/* <Sidebar /> */}
      {/* <UserDetails /> */}
      <Layout />
    </>
  );
}

export default App;
