import React, { useState, useEffect } from "react";
import axios from "axios";

const EditForm = ({ formData, onSave, onCancel, token }) => {
  const initialFormData = {
    id: null,
    name: "",
    sets: "",
    muscle: "",
    description: "",
    images: [],
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [previewImages, setPreviewImages] = useState([]);

  useEffect(() => {
    if (formData) {
      setFormValues({
        id: formData.id || null,
        name: formData.name || "",
        sets: formData.sets || "",
        muscle: formData.muscle || "",
        description: formData.description || "",
        images: formData.images || [],
      });

      // Ensure formData.images is an array before calling map
      const previews = (formData.images || []).map((imageUrl) => ({
        url: imageUrl,
        preview: true,
      }));
      setPreviewImages(previews);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setFormValues((prevValues) => ({ ...prevValues, images: files }));

    // Generate image previews for newly added images
    const previews = files.map((file) => ({
      url: URL.createObjectURL(file),
      preview: false, // Indicates new image
    }));
    setPreviewImages([...previewImages, ...previews]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data for submission
    const data = new FormData();
    data.append("id", formValues.id);
    data.append("name", formValues.name);
    data.append("sets", formValues.sets);
    data.append("muscle", formValues.muscle);
    data.append("description", formValues.description);
    formValues.images.forEach((file, index) => {
      data.append(`images[${index}]`, file);
    });

    try {
      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/exercises/${formValues.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSave(response.data);
    } catch (error) {
      console.error("Error updating exercise:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#252525] pt-10 pb-24 px-16 rounded-2xl shadow-lg shadow-slate-600 max-w-xl w-full mt-10">
        <form className="mt-5 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm space-y-4">
            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Name
              </h2>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                placeholder="Name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Sets
                </h2>
                <input
                  id="sets"
                  name="sets"
                  type="number"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Sets"
                  value={formValues.sets}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <h2 className="text-white text-start text-2xl mb-2 font-bold">
                  Exercise Type
                </h2>
                <input
                  id="muscle"
                  name="muscle"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                  placeholder="Exercise Type"
                  value={formValues.muscle}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Description
              </h2>
              <textarea
                id="description"
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
                rows="4"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
              />
            </div>

            <div className="mb-6">
              <h2 className="text-white text-start text-2xl mb-2 font-bold">
                Images
              </h2>
              <input
                id="images"
                name="images"
                type="file"
                multiple
                className="w-full px-4 py-2 bg-[#202023] border-gray-500 rounded-lg text-white transition duration-300 ease-in-out transform border-4 border-transparent focus:outline-none focus:border-orange-500"
                onChange={handleFileChange}
              />
              <div className="mt-4 grid grid-cols-3 gap-2">
                {previewImages.map((image, index) => (
                  <img
                    key={index}
                    src={image.url}
                    alt={`Preview ${index}`}
                    className="w-24 h-24 object-cover rounded"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-600 focus:outline-none"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-4 rounded-lg shadow hover:bg-green-600 focus:outline-none"
            >
              Edit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
