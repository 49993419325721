import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlusCircle, FaEdit, FaTrash } from "react-icons/fa";
import EditForm from "./EditForm";
import AddForm from "./AddForm";

const Meals = () => {
  const [meals, setMeals] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentMeal, setCurrentMeal] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const token = process.env.REACT_APP_TOKEN;

  useEffect(() => {
    fetchMeals();
  }, [meals]);

  const fetchMeals = async () => {
    try {
      const response = await axios.get(
        "https://api.drmicheladib.com/api/v1/admin/meals?paginate=100",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeals(response.data.data);
    } catch (error) {
      console.error("Error fetching meals:", error);
    }
  };

  const addMeal = async (meal) => {
    try {
      const response = await axios.post(
        "https://api.drmicheladib.com/api/v1/admin/meals",
        meal,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeals([...meals, response.data]);
      setShowAddForm(false);
      return response; // Explicitly return the response
    } catch (error) {
      console.error("Error adding meal:", error);
      throw error; // Rethrow the error to propagate it
    }
  };

  const updateMeal = async (updatedMeal) => {
    if (!updatedMeal.id) {
      console.error("Updated meal does not have an ID");
      return; // Optionally handle this case
    }

    try {
      const response = await axios.post(
        `https://api.drmicheladib.com/api/v1/admin/meals/${updatedMeal.id}`,
        updatedMeal,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMeals(
        meals.map((meal) => (meal.id === updatedMeal.id ? updatedMeal : meal))
      );
      setShowEditForm(false);
      setCurrentMeal(null);
      return response; // Explicitly return the response
    } catch (error) {
      console.error("Error updating meal:", error);
      throw error; // Rethrow the error to propagate it
    }
  };

  const handleSave = (updatedMeal) => {
    updateMeal(updatedMeal);
  };

  const deleteMeal = async (id) => {
    try {
      await axios.delete(
        `https://api.drmicheladib.com/api/v1/admin/meals/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeals(meals.filter((meal) => meal.id !== id));
    } catch (error) {
      console.error("Error deleting meal:", error);
    }
  };

  const handleEditClick = (meal) => {
    setCurrentMeal(meal);
    setShowEditForm(true);
  };

  const handleEditFormChange = (name, value) => {
    setCurrentMeal({ ...currentMeal, [name]: value });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter meals based on search query
  const filteredMeals = meals.filter((meal) => {
    if (meal.title && typeof meal.title === "string") {
      return meal.title.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return false; // Filter out meals without a valid title
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold text-gray-900">
            Meal Dashboard
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none"
            onClick={() => setShowAddForm(true)}
          >
            <FaPlusCircle className="mr-2" />
            Add Meal
          </button>
        </div>
      </div>

      {showAddForm && (
        <AddForm onSave={addMeal} onCancel={() => setShowAddForm(false)} />
      )}
      {showEditForm && (
        <EditForm
          formData={currentMeal}
          onSave={handleSave}
          onCancel={() => setShowEditForm(false)}
          onChange={handleEditFormChange}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="sm:w-1/3 mb-4">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            id="search"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search by Title"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="min-w-full py-2 align-middle inline-block sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Meal Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Calories
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Carbs
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Protein
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Fats
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    tags
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredMeals && filteredMeals.length > 0 ? (
                  filteredMeals.map((meal) => (
                    <tr key={meal.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {meal.title ? meal.title : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.meal_type ? meal.meal_type : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.nutrition_data && meal.nutrition_data.calories
                          ? meal.nutrition_data.calories
                          : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.nutrition_data && meal.nutrition_data.carbs
                          ? meal.nutrition_data.carbs
                          : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.nutrition_data && meal.nutrition_data.protein
                          ? meal.nutrition_data.protein
                          : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {meal.nutrition_data && meal.nutrition_data.fats
                          ? meal.nutrition_data.fats
                          : ""}
                      </td>
                      {/* <td className="max-w-10 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {meal.instructions ? meal.instructions : ""}
                      </td> */}
                      <td className="px-4 py-2">
                        {meal.tags && meal.tags.length > 0
                          ? meal.tags.map((tag, index) => (
                              <div key={index}>
                                <span className="font-semibold">
                                  {tag.name ? tag.name : ""}
                                </span>
                              </div>
                            ))
                          : ""}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {meal.ingredients && meal.ingredients.length > 0
                          ? meal.ingredients.map((ingredient, index) => (
                              <div key={index}>
                                <span className="font-bold">
                                  {ingredient.name ? ingredient.name : ""}
                                  {":"}
                                </span>
                                <span className="ml-10">
                                  {ingredient.quantity
                                    ? ingredient.quantity
                                    : ""}
                                </span>
                                <hr />
                              </div>
                            ))
                          : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          type="button"
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => handleEditClick(meal)}
                        >
                          <FaEdit />
                        </button>
                        <button
                          type="button"
                          className="text-red-600 hover:text-red-900 ml-4"
                          onClick={() => deleteMeal(meal.id)}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="8"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No meals found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meals;
